import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "table"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import request from "../http.js";
import { getCurrentInstance, ref, onMounted } from "vue";

//相当于 vue2 的this 获取全局挂载的变量

export default {
  __name: 'MessageView',
  setup(__props) {
    const {
      appContext
    } = getCurrentInstance();
    const global = appContext.config.globalProperties;

    //常用变量
    let tableData = ref([]);
    const current = ref(1);
    const size = ref(10);
    const pageTotal = ref(10);
    // 分页下拉选项
    let pageSizes = [10, 20, 30, 40, 50, 100];

    //查询组件下拉选的值
    let queryMsg = ["note"];
    let queryIsPush = [{
      value: 0,
      label: "未推送"
    }, {
      value: 1,
      label: "已推送"
    }];
    // 定义搜索查询条件
    let queryParam = ref({
      msgKey: "note",
      isDeleted: null
    });

    //编辑操作相关
    const isShowTip = ref(false);
    let isShowTipTitle = ref("");
    let submitOption = "";
    const handleFormData = ref();
    let clearHandleData = {
      id: "",
      createBy: "",
      msgValue: "",
      msgKey: ""
    };

    //组件加载时
    onMounted(() => {
      getNoteMessage();
    });

    //获取推送笔记信息
    async function getNoteMessage() {
      getMessage(queryParam.value);
    }

    //获取信息
    async function getMessage(query) {
      let result = await request.fetchPost("/msg/list", {
        current: current.value,
        size: size.value,
        data: {
          msgKey: query.msgKey,
          isDeleted: query.isDeleted
        }
      });
      tableData.value = result.data.data.records;
      // 设置分页属性
      setPage(result.data.data);
      // msg = result.data.msg;
      // alertMsg(msg, result.data.success, 3000);
      // console.log(tableData.value, "table");
    }

    function setPage(data) {
      console.log("设置分页当前页：", data.current, "设置总数:", data.total, "设置页面大小:", data.size);
      current.value = data.current;
      size.value = data.size;
      pageTotal.value = data.total;
    }

    // 处理新增或者删除
    function openOperation(type, data) {
      isShowTip.value = true;
      if ("add" == type) {
        isShowTipTitle = "增加备忘提醒";
        //清空表单数据
        handleFormData.value = JSON.parse(JSON.stringify(clearHandleData));
      }
      if ("edit" == type) {
        isShowTipTitle = "修改备忘提醒";
        //表单内容携带
        handleFormData.value = data;
      }
      submitOption = type;
    }
    async function submitOperation() {
      if ("add" == submitOption) {
        //新增
        await handleAdd();
      }
      if ("edit" == submitOption) {
        //新增
        await handleEdit();
      }
      getNoteMessage();
    }
    async function handleAdd() {
      let body = {
        createBy: "cool",
        msgKey: "note",
        msgValue: handleFormData.value.msgValue
      };
      let response = await request.fetchPost("/msg/add", body);
      console.log(response);
      if (response.data.data) {
        //成功后关闭表单
        isShowTip.value = false;
        alertMsg("新增完成");
      }
    }

    //组件点击事件  data 操作的数据
    async function handleDel(data) {
      //弹窗
      await global.$confirm("是否确认删除", "删除").then(async () => {
        let response = await request.fetchGet("/msg/del/" + data.id);
        if (response.data.data) {
          //成功
          alertMsg("成功删除");
        }
      }).catch(() => {
        console.log("取消");
      });
      getNoteMessage();
    }

    //组件编辑事件  data 操作的数据
    async function handleEdit() {
      console.log(handleFormData);
      let body = {
        msgValue: handleFormData.value.msgValue,
        isDeleted: handleFormData.value.isDeleted,
        id: handleFormData.value.id
      };
      let response = await request.fetchPost("/msg/edit", body);
      console.log(response);
      if (response.data.data) {
        //成功后关闭表单
        isShowTip.value = false;
        alertMsg("修改完成");
      } else {
        alertMsg("修改失败");
      }
    }

    //消息弹窗
    function alertMsg(title, flag, time) {
      let type = "falil";
      if (flag) {
        type = "success";
      }
      let msg = {
        title: title,
        type: type,
        duration: time
      };
      console.log(global);
      global.$notify(msg);
    }
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, {
        justify: "start",
        style: {
          "margin-bottom": "20px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 3
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParam).msgKey,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParam).msgKey = $event),
            placeholder: "选择key",
            onChange: _cache[1] || (_cache[1] = $event => getNoteMessage())
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(queryMsg), msg => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: msg,
                value: msg
              }, null, 8, ["value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_col, {
          offset: 1,
          span: 3
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParam).isDeleted,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParam).isDeleted = $event),
            placeholder: "是否已推送",
            onChange: _cache[3] || (_cache[3] = $event => getNoteMessage()),
            clearable: "",
            filterable: "",
            "default-first-option": ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(queryIsPush), isPush => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: isPush,
                value: isPush.value,
                label: isPush.label
              }, null, 8, ["value", "label"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_col, {
          offset: 12,
          span: 3
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            round: "",
            onClick: _cache[4] || (_cache[4] = $event => openOperation('add', null))
          }, {
            default: _withCtx(() => [_createTextVNode("新增待办")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
            data: _unref(tableData),
            style: {
              "width": "90%"
            },
            fit: "",
            border: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table_column, {
              fixed: "",
              prop: "id",
              label: "id",
              "min-width": "30px",
              align: "center"
            }), _createVNode(_component_el_table_column, {
              prop: "createData",
              label: "日期",
              align: "center"
            }), _createVNode(_component_el_table_column, {
              prop: "msgValue",
              label: "内容",
              "min-width": "120px",
              "show-overflow-tooltip": "",
              align: "center"
            }), _createVNode(_component_el_table_column, {
              prop: "createBy",
              label: "创建人",
              "min-width": "40px",
              align: "center"
            }), _createVNode(_component_el_table_column, {
              prop: "isDeleted",
              label: "是否已推送",
              "min-width": "50px",
              align: "center",
              formatter: row => {
                if (row.isDeleted) {
                  return '已推送';
                } else {
                  return '未推送';
                }
              }
            }, null, 8, ["formatter"]), _createVNode(_component_el_table_column, {
              fixed: "right",
              label: "操作",
              "min-width": "40px",
              align: "center"
            }, {
              default: _withCtx(scope => [_createVNode(_component_el_button, {
                link: "",
                type: "primary",
                size: "small",
                onClick: $event => openOperation('edit', scope.row)
              }, {
                default: _withCtx(() => [_createTextVNode("修改")]),
                _: 2
              }, 1032, ["onClick"]), _createVNode(_component_el_button, {
                link: "",
                type: "primary",
                size: "small",
                onClick: $event => handleDel(scope.row)
              }, {
                default: _withCtx(() => [_createTextVNode("删除")]),
                _: 2
              }, 1032, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["data"])])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 10,
          offset: 7
        }, {
          default: _withCtx(() => [_createVNode(_component_el_pagination, {
            background: "",
            layout: " prev, pager, next,sizes,total",
            total: pageTotal.value,
            "page-sizes": _unref(pageSizes),
            "current-page": current.value,
            "onUpdate:currentPage": _cache[5] || (_cache[5] = $event => current.value = $event),
            "page-size": size.value,
            "onUpdate:pageSize": _cache[6] || (_cache[6] = $event => size.value = $event),
            "pager-count": "9",
            onCurrentChange: _cache[7] || (_cache[7] = $event => getNoteMessage()),
            onSizeChange: _cache[8] || (_cache[8] = $event => getNoteMessage())
          }, null, 8, ["total", "page-sizes", "current-page", "page-size"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: isShowTip.value,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => isShowTip.value = $event),
        title: _unref(isShowTipTitle),
        width: "50%",
        "destroy-on-close": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: _cache[11] || (_cache[11] = () => {
            isShowTip.value = false;
          })
        }, {
          default: _withCtx(() => [_createTextVNode("取消 ")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[12] || (_cache[12] = $event => submitOperation())
        }, {
          default: _withCtx(() => [_createTextVNode(" 确认 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: handleFormData.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "待办内容",
            "label-width": 120
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: handleFormData.value.msgValue,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => handleFormData.value.msgValue = $event),
              autosize: {
                minRows: 3,
                maxRows: 10
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _unref(submitOption) == 'edit' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "是否推送",
            "label-width": 140
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              class: "selTermCss",
              modelValue: handleFormData.value.isDeleted,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => handleFormData.value.isDeleted = $event),
              placeholder: "是否推送"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(queryIsPush), key => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: key.value,
                  value: key.value,
                  label: key.label
                }, null, 8, ["value", "label"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }
};