import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createBlock(_component_el_row, {
    gutter: 8
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, null, {
      default: _withCtx(() => [_createVNode(_component_el_text, {
        class: "mx-1",
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("aaaa")]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}