import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
import { getCurrentInstance, onMounted, ref, onUpdated } from "vue";

//相当于 vue2 的this 获取全局挂载的变量

export default {
  __name: 'NowTerm',
  props: {
    term: {
      default: []
    },
    nowTerm: {
      default: {}
    }
  },
  emits: ['nowTerm', 'update:nowTerm'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const {
      appContext
    } = getCurrentInstance();
    const global = appContext.config.globalProperties;
    const nowTerm = ref(props.nowTerm);

    // 初始化方法
    onMounted(() => {});
    onUpdated(() => {
      //prpos更新后
      nowTerm.value = props.nowTerm;
    });

    //更新学期
    function updateNowTerm() {
      var now = nowTerm.value;
      console.log("子组件更新nowTerm", now);
      emits('update:nowTerm', now);
    }
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createBlock(_component_el_select, {
        class: "selTermCss",
        modelValue: nowTerm.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => nowTerm.value = $event),
        placeholder: "选择学期",
        onChange: updateNowTerm
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.term, key => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: key.id,
            value: key.id,
            label: key.termName
          }, null, 8, ["value", "label"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};